<template>
  <v-menu transition="slide-y-transition" nudge-left="40" offset-y v-model="showMenu">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card class="mx-auto" max-width="300">
      <v-list dense>
        <v-list-item v-for="(action, i) in actions" :key="i" :disabled="action.disabled"
          @click.stop="actionClicked(action)">
          <v-list-item-action :disabled="action.disabled">
            <v-icon>{{ action.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class=" mr-3">
            <v-list-item-title>{{ action.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <ContractUploadModal
      v-if="uploadModal"
      v-model="uploadModal"
      :reupload="true"
      :uploadId="selectedContract.upload_id"
      @closeUpload="closeUploadModal">
    </ContractUploadModal>
    <CancelDialog
      v-if="cancelDialog"
      v-model="cancelDialog"
      :selectedContract="selectedContract"
      @cancel="cancel"
      @closeCancel="closeCancelDialog">
    </CancelDialog>
  </v-menu>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
const ContractUploadModal = () => import('@/components/contracts/ContractUploadModal.vue')
const CancelDialog = () => import('@/components/contracts/CancelDialog.vue')
export default {
  name: 'ContractActions',
  mixins: [userAccess, displayAlert],
  components: { ContractUploadModal, CancelDialog },
  props: [
    'selectedContract'
  ],
  data() {
    return {
      showMenu: false,
      uploadModal: false,
      cancelDialog: false
    }
  },
  computed: {
    actions() {
      return [
        { label: 'Cancel', value: 'cancel', icon: 'mdi-cancel', disabled: false, show: !["cancelled", "completed", "completed_with_warnings"].includes(this.status) },
        { label: 'Rerun', value: 'rerun', icon: 'mdi-refresh', disabled: false, show: !["running", "pending", "cancelled", "completed"].includes(this.status) },
        { label: 'Download Warning Log', value: 'download_warnings', icon: 'mdi-file-outline', disabled: false, show: this.status == 'completed_with_warnings' },
        { label: 'Download Contract File', value: 'download', icon: 'mdi-download', disabled: false, show: true }
      ].filter(action => action.show)
    },
    status() {
      return this.selectedContract.upload_status.toLowerCase()
    }
  },
  methods: {
    actionClicked(action) {
      switch (action.value) {
        case 'download':
          this.download()
          break
        case 'rerun':
          this.rerun()
          break
        case 'cancel':
          this.cancelDialog = true
          break
        case 'download_warnings':
          this.downloadWarningLog()
          break
      }
    },
    async download () {
      try {
        const { data } =  await ContractUpload.downloadContract({ upload_id: this.selectedContract.upload_id })
        if (data) {
          location.href = data.url
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async rerun () {
      try {
        this.uploadModal = true
      } catch (err) {
        this.handleError(err)
      }
    },
    async cancel () {
      try {
        await ContractUpload.cancelContractUpload(this.selectedContract.upload_id)
        this.$emit('refresh')
        this.showMenu = false
      } catch (err) {
        this.handleError(err)
      }
    },
    async downloadWarningLog () {
      try {
        const { data } =  await ContractUpload.downloadWarningLog(this.selectedContract.upload_id)
        if (data) {
          location.href = data.url
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    closeUploadModal () {
      this.uploadModal = false
    },
    closeCancelDialog () {
      this.cancelDialog = false
    }
  }
}
</script>